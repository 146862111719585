import * as React from 'react';
import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Link,  } from "gatsby"

import { globalStyles } from '../../themes/global';

const layoutWidth = 1280;
const layoutPadding = '1em';

// @todo DRY
const ContentBlock = styled.div`
    padding: 0 ${layoutPadding};
    max-width: ${layoutWidth}px;
    margin: 0 auto;
`;
const Wrapper = styled.div`
    /* font-family: 'Open Sans', sans-serif; */
    /* font-family: 'Noto Sans JP', sans-serif; */
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    line-height: 1.4;
`;
const TopMenuBlock = styled.ul`
    /* background: rgba(127, 127, 127, .9); */
    background: #fff;
    box-shadow: 0 0 .1em rgba(0, 0, 0, .5);

    @media screen and (min-width: ${720}px) {
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
    }
`;
const TopMenu = styled.ul`
    list-style: none;
    font-size: .45em;
    text-transform: uppercase;

    @media screen and (min-width: ${720}px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    a {
        display: block;
        /* color: rgba(255, 255, 255, .85); */
        color: rgba(63, 63, 63, .85);
        padding: .5em 1em .25em;
        border-bottom: .25em solid transparent;
        text-align: center;

        &.menu-item-active {
            border-bottom-color: dodgerblue;
        }

        &:hover {
            background-color: rgba(0, 0, 0, .05);
        }
    }
`;

const footerBorderColor = '#eee';
const Footer = styled.div`
    background-color: #ccc;
    /* color: #777; */
    color: rgba(0, 0, 0, .66);
    font-family: 'Inconsolata', monospace;
    font-size: .45em;
    line-height: 1;
    text-align: center;
    padding: .5em 0;
    /* border-top: 1px solid ${footerBorderColor}; */
    /* box-shadow: 0 0 .5em rgba(0, 0, 0, .5); */
`;

const menu = [
    {
        name: 'Home',
        path: '/'
    },
    {
        name: 'Webdev',
        path: '/webdev'
    },
    {
        name: 'Gamedev',
        path: '/gamedev'
    },
    {
        name: 'Graphic Art',
        path: '/graphic-art'
    },
    {
        name: 'Photography',
        path: '/photography'
    },
]

export const DefaultLayout: React.FunctionComponent<{}> = (props) => {
    const [email, setEmail] = React.useState('');

    React.useEffect(() => {
        const emailArr = ['lukasz', '(at)', 'grolik', '.', 'pl'];
        setEmail(emailArr.join('').replace('(at)', '@'));
    }, []);

    return <>
        <Helmet>
            <title>Łukasz Grolik | homepage</title>

            {/* <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap" rel="stylesheet" /> */}
            {/* <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap" rel="stylesheet" /> */}
        </Helmet>

        <Global styles={globalStyles} />

        <Wrapper>
            <TopMenuBlock>
                <ContentBlock>
                    <TopMenu>
                        {
                            menu.map(item => {
                                return (
                                    <li key={item.name}>
                                        <Link
                                            to={item.path}
                                            activeClassName="menu-item-active"
                                        >{item.name}</Link>
                                    </li>
                                )
                            })
                        }
                    </TopMenu>
                </ContentBlock>
            </TopMenuBlock>

            <div>{props.children}</div>

            <div>
                {/* @todo move footer to layout */}
                <Footer>
                    <div>2013-2022 <span style={{ fontSize: '1.5em' }}>©</span> Łukasz Grolik <span>|</span> {email}</div>
                </Footer>
            </div>
        </Wrapper>

        {/* <script>
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

    ga('create', 'UA-53853356-1', 'auto');
    ga('send', 'pageview');
    </script> */}
    </>
}
